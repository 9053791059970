<template>
  <div id="app" :class="{'dark-mode': isDarkMode}">
    <header>
      <div class="title-bar">
        <h1 class="belkamc-title">BelkaMC</h1>
      </div>
      <div class="nav-bar">
        <div class="nav-left">
          <router-link to="/">Home</router-link>
          <router-link to="/team">Team</router-link>
          <!-- a href="https://belkamc.fandom.com/de/wiki/BelkaMC_Wiki" target="_blank" rel="noopener noreferrer">Fandom</a -->
           <router-link to="/fandom">Fandom</router-link>
        </div>
        <div class="nav-right">
          <router-link to="/was-ist-belkamc">Was ist BelkaMC</router-link>
          <router-link to="/youtube">YouTube</router-link>
          <router-link to="/impressum">Impressum</router-link>
        </div>
      </div>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <span class="left">©2023-2024 BelkaMC</span>
      <span class="right">made by zeyyco</span>
    </footer>
    <div class="dark-mode-toggle" @click="toggleDarkMode">
      <div class="toggle-circle">
        <span>{{ isDarkMode ? 'on' : 'off' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isDarkMode: false,
    };
  },
  mounted() {
    if (localStorage.getItem('darkMode') === 'true') {
      this.isDarkMode = true;
    }
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('darkMode', this.isDarkMode);
    },
  },
};
</script>

<style>
:root {
  --background-color-light: #f8f8f8;
  --background-color-dark: #1f1f1f;
  --text-color-light: #333;
  --text-color-dark: #ffffff;
  --header-bg-light: #e0e0e0;
  --header-bg-dark: #1c1c1c;
  --nav-bg-light: #00bcd4;
  --nav-bg-dark: #025a69;
  --footer-bg-light: #e0e0e0;
  --footer-bg-dark: #1c1c1c;
  --title-color-light: #00bcd4;
  --title-color-dark: #025a69;
  --title-shadow-light: #333;
  --title-shadow-dark: #000;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  overflow-x: hidden;
}

.dark-mode {
  --background-color-light: var(--background-color-dark);
  --text-color-light: var(--text-color-dark);
  --header-bg-light: var(--header-bg-dark);
  --nav-bg-light: var(--nav-bg-dark);
  --footer-bg-light: var(--footer-bg-dark);
  --title-color-light: var(--title-color-dark);
  --title-shadow-light: var(--title-shadow-dark);
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
  background-color: var(--header-bg-light);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.title-bar {
  text-align: center;
  padding: 40px 0;
  background-color: var(--header-bg-light);
  overflow: visible;
  position: relative;
}

.belkamc-title {
  margin: 0;
  font-size: 3.5em;
  font-weight: bold;
  color: var(--title-color-light);
  text-shadow: 
    -2px -2px 0 var(--title-shadow-light),  
    2px -2px 0 var(--title-shadow-light),
    -2px 2px 0 var(--title-shadow-light),
    2px 2px 0 var(--title-shadow-light);
  font-family: 'Roboto', sans-serif;
  line-height: 1.2;
}

.dark-mode-toggle {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: var(--nav-bg-light);
  border: none;
  padding: 5px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.dark-mode-toggle .toggle-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--title-color-light);
  color: white;
  font-weight: bold;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.dark-mode-toggle .toggle-circle span {
  color: white;
}

.dark-mode .dark-mode-toggle .toggle-circle {
  background-color: var(--title-color-dark);
}

.nav-bar {
  background-color: var(--nav-bg-light);
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}

.nav-left,
.nav-right {
  display: flex;
  gap: 40px;
}

.nav-left {
  margin-left: 100px;
}

.nav-right {
  margin-right: 100px;
}

.nav-bar a {
  color: var(--title-shadow-light);
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.5em;
}

.dark-mode .nav-bar a {
  color: var(--title-shadow-dark);
}

.nav-bar a:hover {
  text-decoration: underline;
}

main {
  flex: 1;
  padding: 20px;
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dark-mode main {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}


@media (max-width: 768px) {
  .belkamc-title {
    font-size: 2.5em;
  }
  
  .nav-bar {
    flex-direction: column;
    align-items: center;
  }

  .nav-left,
  .nav-right {
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .belkamc-title {
    font-size: 2em;
  }

  .nav-bar {
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
  }

  .nav-left,
  .nav-right {
    margin: 5px 0;
  }
}

footer {
  width: 100%;
  background-color: var(--footer-bg-light);
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
}

footer span {
  font-size: 14px;
}

footer .left {
  text-align: left;
  padding-left: 20px;
  color: var(--text-color-light);
}

footer .right {
  text-align: right;
  padding-right: 20px;
  color: var(--text-color-light);
}

.dark-mode footer .left,
.dark-mode footer .right {
  color: var(--text-color-dark);
}
</style>


