<template>
  <div class="wasistbelka-container">
    <h1 class="wasistbelka-title">Was ist BelkaMC</h1>
    <div class="wasistbelka-content">
      <p>
        BelkaMC ist ein spannendes RolePlay-Projekt, das hauptsächlich in Minecraft stattfindet.
        Die Handlung spielt im Jahr 1918 in einer fiktiven Welt, die eine reiche und komplexe Geschichte bietet.
        Im Zentrum dieser Welt steht das fiktive Land „Belka“, von dem auch der Name „BelkaMC“ stammt.
      </p>
      <p>
        Neben Belka existieren in dieser Welt auch andere faszinierende Länder wie Drustan, Pixatown, Gottreich und viele weitere.
        Jedes dieser Länder bringt seine eigenen Geschichten, Kulturen und Charaktere mit sich, was das RolePlay-Erlebnis noch vielschichtiger und aufregender macht.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WasIstBelkaMC',
};
</script>

<style scoped>
.wasistbelka-container {
  padding: 20px;
}

.wasistbelka-title {
  font-family: 'Roboto', sans-serif;
  font-size: 2em;
  color: var(--title-color-light);
  text-shadow: -2px -2px 0 var(--title-shadow-light),
               2px -2px 0 var(--title-shadow-light),
              -2px  2px 0 var(--title-shadow-light),
               2px  2px 0 var(--title-shadow-light);
  margin-bottom: 30px;
  text-align: left;
  margin-left: 20px;
}

.dark-mode .wasistbelka-title {
  color: var(--title-color-dark);
  text-shadow: -2px -2px 0 var(--title-shadow-dark),
               2px -2px 0 var(--title-shadow-dark),
              -2px  2px 0 var(--title-shadow-dark),
               2px  2px 0 var(--title-shadow-dark);
}

.wasistbelka-content {
  font-size: 1.1em;
  line-height: 1.6;
  color: var(--text-color-light);
  text-align: left;
  margin-left: 20px;
}

.dark-mode .wasistbelka-content {
  color: var(--text-color-dark);
}
</style>
